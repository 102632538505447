import React from 'react';
import { Redirect } from 'react-router-dom';
import { useEffect } from "react";

import './AlloanIntern.css';

const HIRING_URL = "https://giddy-ixia-df6.notion.site/Hiring-Frontend-Intern-at-Alloan-ai-18ef0a0c15ab80aa897dd0077a81e6b3"
const ALLOAN_LINKEDIN_URL = "https://www.linkedin.com/company/alloan-ai"
const DVSJ_LINKEDIN_URL = "https://www.linkedin.com/in/dvsj"
const WaveText = () => {  
  return (
    <div className="wordart-superhero">
        <div className="wordart-superhero__text">
            <span>A</span>
            <span>m</span>
            <span>a</span>
            <span>Z</span>
            <span>i</span>
            <span>N</span>
            <span>G</span>
            <span>g</span>
        </div>
    </div>
  );
};

function AlloanIntern() {
  useEffect(() => {
    // window.location.href = HIRING_URL;
    // window.open(HIRING_URL, "_blank", "noopener,noreferrer");
  }, []);

  return (
    <div id="hiring-container">
      <h1>
        Let'ss GooO0OoO!
      </h1>
      <div className='flame'></div>
      <img className="hiring-gif" src='img/lfg.gif' alt="let's goo!" />
      <div>
        How do you like my 
        <WaveText />  
        page? 
        <div className="hiring-footer">
          (this is why they don't let <a className='hiring-url' href={DVSJ_LINKEDIN_URL} target="_blank" rel="noreferrer">me</a> do the frontend at <a className='hiring-url' href={ALLOAN_LINKEDIN_URL} target="_blank" rel="noreferrer">my company</a> and we're looking for people like you) <br /> 
          <a className='hiring-url' href={HIRING_URL} target="_blank" rel="noreferrer">
            View the intern job posting
          </a>
        </div>
      </div>      
    </div>
  );
}

export default AlloanIntern;

