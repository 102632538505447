import React, { Fragment } from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import './App.css';
import { OptionCards, Blog, Work, About, Footer } from '..';
import { OpenGraph } from '../pages'
import { PossibleFonts } from '../pages'
import { NoHello } from '../pages'
import { Goodbye } from '../pages'
import { BadTwitter } from '../pages'
import { Time } from '../pages'
import { Matches } from '../pages'
import { Confessions } from '../pages'
import { AlloanIntern } from '../pages'

/**
 * Components:
 * [x] Name area 
 * [x] Info card
 * [_] Bottom panel
 */

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/intern" component={AlloanIntern} />
        <Route path="/matches" component={Matches} />
        <Route path="/confessions" component={Confessions} />
        <Route path="/open-graph" component={OpenGraph} />
        <Route path="/fonts" component={PossibleFonts} />
        <Route path="/byebye" component={Goodbye} />
        <Route path="/bad-twitter" component={BadTwitter} />
        <Route path="/time" component={Time} />
        <Route path="/(.*bye.*)">
          <Redirect to="/byebye" />  
        </Route>
        <Route path="/nohello" component={NoHello} />
        <Route path="/(.*hello.*|.*call.*)">
          <Redirect to="/nohello" />  
        </Route>
        <Route component={WebsiteHomepage} />
      </Switch>
    </div>
  );
}

function WebsiteHomepage() {
  return (
    <Fragment>
      <Link to="/" id="app-home-link">
        <HeroName name="davis jeffrey"/>
      </Link>
      <OptionCards/>
      <Switch>
        <div>
          <Route path="/about" component={About} />
          <Route path="/work" component={Work} />
          <Route path="/blog" component={Blog} />
        </div>
      </Switch>
      <Footer />
    </Fragment>
  );
}

function HeroName(props: { name: String; }) {
  return (
    <div id="Hero__Name" data-testid="hero-name">
      {props.name}
    </div>
  );
}

export default App;
